@use '../../../scss/' as *;
.img-box {
    @include transition3;
    position: relative;
    padding: 20px 20px 68px;  
    background: #0e2230;
    text-align: center;
    &::after {
        @include transition3;
        position: absolute;
        content: "";
        border: 1px solid rgba(34, 183, 143, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &::before {
        @include transition3;
        position: absolute;
        content: "";
        border: 2px solid rgba(34, 183, 143, 0);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &.active,
    &:hover {
        box-shadow: $box-shadow;
        &::before {
            border-color: #14C2A3;
            border: 2px solid #14C2A3;
        }
    }
    .content {
        position: absolute;
        bottom: 18px;
        left: 20px;
        z-index: 1;
        a {
            font-size: 20px;
            line-height: 1.4;
            text-transform: uppercase;
            color: $white;
            font-family: 'cybox', sans-serif;
            &:hover {
                color: $color-hover;
            }
        }
    }
    &:hover {
        cursor: context-menu;
    }
    .click-nft {
        position: relative;
        z-index: 1;
    }
}

.home-2 .img-box {
    margin-bottom: 30px;
}

.page-nft {
    .img-box {
        margin-bottom: 32px;
        
    }
    .tf-button {
        margin-top: 0;
    }
}